import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/SimplePage';

export const simplePageData = () => {
  const data = useStaticQuery(graphql`
    query simplePageQuery {
      dGraphQl {
        nodeQuery(
          filter: {
            conditions: {
              field: "type",
              value: "page"
            }
          }
        ) {
          entities {
            ... on DrupalGraphQl_NodePage {
              entityLabel
              entityUrl {
                path
              }
              uuid
              body {
                value
                processed
              }
              fieldMetaTitle
              fieldMetatags {
                entity {
                  ... on DrupalGraphQl_ParagraphMetatags {
                    fieldDescription
                    fieldKeywords
                    entityBundle
                  }
                }
              }
              fieldBannerTop {
                entity {
                  ...DrupalGraphQlParagraphBannerPage
                }
              }
              fieldHreflang {
                entity {
                  ...ParagraphHreflang
                }
              }
            }
          }
        }
      }
    }
  `);

  return transformers.simplePageTransform(data.dGraphQl);
}


